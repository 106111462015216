/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */


 import React from "react" 
 import { Transition } from '@headlessui/react' 
 import MainMenuItem from "./MainMenuItem"
 import {default as Logo} from '../../images/logoslogan.svg';
import { HomeIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";


 const MainMenu = (props) => {
   const open = props.open
   const setOpen = props.setOpen
   const menu = props.menu
   const white = props.white
 
   return (
     <>
      <nav className="hidden lg:flex tracking-tight h-full"> 
          <div className="relative  h-full ">
                  <div className=" flex h-full ">
                    <Link to={'/'}  
                      className={"group border-b-2 border-transparent inline-flex items-center text-sm   focus:outline-none   px-3 py-3  h-full mr-1 hover:border-white " }
                      activeClassName={" border-yellow-400 " }
                      >
                      <span><HomeIcon className="w-5 h-5" /></span>
                      
                      </Link>
                  </div>
            </div>
          {menu.map((page, i) => (
            <MainMenuItem item={page} key={i} white={white} /> 
        ))}

      </nav>

       <div className=" lg:hidden">
          <button onClick={() => setOpen(true) }  type="button" className="lg:hidden lg:w-0 mr-2 p-2 inline-flex items-center justify-center   focus:outline-none ">
            <span className="sr-only">Open menu</span>
            
            <svg className="h-8 w-8 text-gray-100 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
     <Transition
     show={open}
     enter=" duration-150 ease-out "
     enterFrom="opacity-0 "
     enterTo="opacity-100 "
     leave=" duration-150 ease-in "
     leaveFrom="opacity-100 "
     leaveTo="opacity-0 "
     > 
     <div className="   fixed h-screen inset-0 bg-black bg-opacity-25 lg:hidden" aria-hidden="true" onClick={() => setOpen(false) }></div>
     </Transition>
 
     <Transition
       show={open}
       enter="duration-150 ease-out "
       enterFrom="opacity-0 scale-95 "
       enterTo="opacity-100 scale-100 "
       leave="duration-150 ease-in "
       leaveFrom="opacity-100 scale-100 "
       leaveTo="opacity-0 scale-95"
     > 
 
     <div className=" absolute top-2 inset-x-0 p-2 transition transform origin-top-right lg:hidden text-gray-800">
       <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
         <div className="pt-5 pb-6 px-5">
           <div className="flex items-center justify-between">
             <div>
              <img src={Logo} className="h-10  " alt="Logo" />
             </div>
             <div className="-mr-2">
               <button onClick={() => setOpen(false) }  type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                 <span className="sr-only">Close menu</span>
                 {/* <!-- Heroicon name: outline/x --> */}
                 <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                 </svg>
               </button>
             </div>
           </div>
           <div className="mt-6">
             <nav className="grid ">
                <div className="relative w-full">
                      <div className=" flex  ">
                        <Link to={'/'}  
                          className={"group border-b-2 border-transparent inline-flex items-center text-sm   focus:outline-none   px-3 py-3  h-full mr-1 hover:border-white w-full " }
                          activeClassName={" border-yellow-400 " }
                          >
                          <span><HomeIcon className="w-5 h-5" /></span>
                          
                          </Link>
                      </div>
                </div>
               {menu.map((page, i) => (
                <MainMenuItem item={page} key={i} /> 
               ))}
                
             </nav>
           </div>
         </div> 
       </div>
     </div>
     </Transition>
     </div>
     </>
   )
 }
  
 export default MainMenu
 