import { Link } from "gatsby" 
import React, {useState } from "react"
import {default as Logo} from '../images/domytrio-white.svg'; 
import MainMenu from "./layout/MainMenu";

export default function Header({menu, white}) { 
  const [open, setOpen] = useState(false);

  const [headerClassName, setHeaderClassName] = useState('');

  const handleScroll = () => {
      if (window.pageYOffset >= 10) {
          setHeaderClassName('bg-gray-700');
      } else {
          setHeaderClassName('');
      }
  }

  React.useEffect(() => {
      window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

  
  return (
  <> 
  {/* <div className=" bg-gradient-to-b from-black to-transparent opacity-70 h-60 z-30 w-full fixed top-0">

</div> */}
  <div className={"fixed h-20  z-50 w-full top-0 transition-all ease-in-out duration-300 bg-black bg-opacity-70  " + headerClassName }>
    
    <div className="mx-auto max-w-6xl h-full">
      
      <div className="flex justify-between items-center h-full  lg:justify-start  ">
        <div className="flex items-center justify-start lg:w-0 lg:flex-1 ml-7 ">
          <Link to={'/'} className="text-white space-x-6 divide-x  divide-gray-100 divide-opacity-30 flex items-center" >
            <img src={Logo} className="h-8 lg:h-12" alt="Logo" />
            <span className="uppercase pl-6 tracking-wide text-sm">Kamenice</span>
          </Link>
        </div>
        <div >
           
        </div>
        <div className="lg:order-1 flex items-center h-full">
          <div className="lg:order-2 flex justify-end lg:flex-1 lg:ml-10">
          

          </div>
          <div className={"lg:order-1 flex items-center h-full text-gray-100 " }>
            <MainMenu open={open} setOpen={setOpen} menu={menu} white={white} />

          </div>
        </div>
         
      </div>
    </div> 
      
  </div> 
  </>
  )
}

