import { useStaticQuery, graphql } from "gatsby" 
import React, {useState, useEffect } from "react" 
import PropTypes from "prop-types" 
import Header from "./header"   
import Footer from "./footer"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ children,location }) => {
  const data = useStaticQuery(graphql`
  query Pages {
    scomos {
      pageFindAll(menu:"main") {
        id
        parent 
        menuName
        menu
        url
        lft
      }
    }
  }
`)

 
 const [ menu, setMenu ] = useState([]); 
 
 useEffect(() => {  
   let items = []; 
   let mainId = 0;

   function menuItems() { 
       data.scomos.pageFindAll.forEach((menuItem) => {
         if(menuItem.parent==='0') { 
           mainId = menuItem.id
         }
         items[menuItem.id] = menuItem
       })
 
       let newItems = [...items]
       items.forEach((menuItem) => {
         if(items[menuItem.parent]) {
           if(!items[menuItem.parent]['children']) {
             newItems[menuItem.parent]['children'] = [];
           }
           newItems[menuItem.parent]['children'][menuItem.lft] = menuItem
         }
       })

       let sorted = []
       let i = 0;
       newItems[mainId]['children'].forEach((newMenuItem) => {
         sorted[newMenuItem.lft] = newMenuItem
         i++ 
       }); 
 
       return sorted;
     }

    let newMenu = menuItems();
    setMenu(newMenu); 
   // eslint-disable-next-line
 }, []); 
   
  return (
    <>
     {/* {location?.pathname !== '/' && <>
      <div className="h-144 overflow-hidden w-full fixed top-0 ">
        <StaticImage src={'../images/gallery/06.jpg'} className=" object-cover w-full h-full" />
        <div className="h-72  w-full absolute bottom-0 bg-gradient-to-t from-white to-transparent "></div> 
      </div>
        </>
    } */}
    <div className="relative pt-24 bg-gray-200 pb-10 ">

      <Header menu={menu} /> 
      <main className="px-2" >
        {children}
      </main>
        
    </div>
      <Footer />
      
    
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
